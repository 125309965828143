import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import ATG_logo from "../../images/atg.png"
import Seed_Planet from "../../images/atg/seed_planet.png"
import ATG_Ceo from "../../images/atg/atg_owner.jpg"
import ATG_story from "../../images/atg/atg_story_1.jpg"
import ATG_Service from "../../images/atg/atg-service.jpg"
import ATG_Challenge from "../../images/atg/challenge.png"
import ATG_Foot from "../../images/atg/micro-kit-1.png"

import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import { Link } from "gatsby";
import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Case Study on Allthatgrows | High ROAS during Pandemic</title>
                    <meta name="description"
                        content="Read our case study on how AllThatGrows retained a ROAS of 3.27X on Paid campaigns throughout the pandemic with CueForGood." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="How we helped AllThatgrows maintain an average ROAS of 3.27x despite the Pandemic." />
                    <meta property="og:description"
                        content="Read our case study on how AllThatGrows retained a ROAS of 3.27X on Paid campaigns throughout the pandemic with CueForGood." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="How we helped AllThatgrows maintain an average ROAS of 3.27x despite the Pandemic." />
                    <meta name="twitter:title"
                        content="How we helped AllThatgrows maintain an average ROAS of 3.27x despite the Pandemic." />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page high-roas-atg">

                     <div className="case-wrap  ">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img src={ATG_logo} alt="ATG logo" /></h1>
                                 <p className="perennialcycle_p">How CueForGood helped AllThatGrows maintain an average ROAS of <strong>3.27x</strong> through Facebook/ Instagram ads, over 3 years despite the pandemic and the iOS14 update.</p>
                                  <div className="cycle-top"><img src={Seed_Planet} alt="Seed Planet" /></div>
                              </div>
                           </div>
                     </div>

                     <div className="overall-roas ">
                        <div className="container">
                           <div className="out-roas">
                           <div className="inn-roas">
                              <h2>3.27x</h2>
                              <p>Overall ROAS</p>
                           </div>
                           <div className="inn-roas">
                              <h2>60,012</h2>
                              <p>Average Clicks</p>
                           </div>
                           <div className="inn-roas">
                              <h2>39,94,569</h2>
                              <p>Average Impressions</p>
                           </div>
                           </div>
                        </div>
                     </div>

                     <div className="about-client ">
                        <div className="container">
                           <p className="note"><strong>Note:</strong> Results have been calculated using the data spanning 3 years, from April 2019 to August 2021. </p>

                           <div className="cb_good_section call-action-highroas">
                              <Calltoaction sid="ansel1"/>
                           </div>

                           <div className="about-client-in">
                              <div className="about-client-left">
                                 <h2 className="heading_main">About the Client </h2>
                                 <p>AllThatGrows is an e-commerce store that provides superior quality seeds of various vegetables, herbs, fruits, microgreens, and flowers. 
                                    With the sole aim of creating sustainable and self-sufficient homegardens, this digital store inspired by nature has been supplying non-GMO, 
                                    non-hybrid, open-pollinated, and heirloom seeds since 2017.</p>
                                    <p>For more information, visit <a href="https://www.allthatgrows.in/" target="_blank" title="opens in new tab" className="focus-visible">www.AllThatGrows.in</a></p>
                              </div>
                              <div className="about-client-right">
                                 <img src={ATG_Ceo} alt="ATG" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="about-client atg-story">
                        <div className="container">
                           <div className="about-client-in">
                              <div className="about-client-left">
                                 <h2 className="heading_main">Story </h2>
                                 <p>The CueForGood team has been working with AllThatGrows right from when it made its debut in January 2017. From conceptualising product packaging,
                                     website designing, and email marketing to running paid campaigns, we have been offering integrated marketing and communication strategies to help 
                                     the brand grow from scratch.</p>
                              </div>
                              <div className="about-client-right">
                                 <img src={ATG_story} alt="ATG story" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="about-client service-offer ">
                        <div className="container">
                           <div className="about-client-in">
                              <div className="about-client-left">
                                 <h2 className="heading_main">Services we’ve & continue to offer</h2>
                                 <ul>
                                    <li>Product Packaging</li>
                                    <li>Website Design + Development (Shopify)</li>
                                    <li>Marketing Automation & Email Marketing</li>
                                    <li>How To Grow App Development + Management</li>
                                    <li>Marketplace Management (Amazon, Flipkart, & Shopclues)</li>
                                    <li>Campaign Management (Adwords, Organic Search, Blogging, FB/Instagram Ads) </li>
                                 </ul>
                              </div>
                              <div className="about-client-right">
                                 <img src={ATG_Service} alt="ATG Service" />
                              </div>
                           </div>
                        </div>
                     </div>
               
                     <div className="goal-stat ">
                        <div className="container">
                           <div className="out-goal-stat">
                           <div className="inn-goal-stat">
                               <h2 className="heading_main">Goal</h2>
                              <p>Charging up online seed sales through Facebook and Instagram ad campaigns</p>
                           </div>
                           <div className="inn-goal-stat">
                              <h2 className="heading_main">Strategy</h2>
                              <p>See, Think, Do</p>
                              <p>This strategy involves creating ad campaigns with different targeting techniques for each stage of the marketing funnel.</p>
                           </div>
                           </div>
                        </div>
                     </div>

                     <div className="Challenges ">
                        <div className="container">
                           <h2 className="heading_main">Challenges</h2>
                           <div className="out-Challenges">
                           <div className="inn-Challenges">
                               <h2 className="heading_count">1</h2>
                               <h3>Zeroing in on the potential customer base</h3>
                              <p>Discerning a specific set of audience and customer persona was a challenge since we had absolutelyno previous data at our disposal. 
                                 The joyous challenge of working on a brand from the ground up!</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">2</h2>
                               <h3>Minimal brand awareness</h3>
                              <p>When we started out back in 2019, we realized that there were almost no searches with the brand name. And if the consumer isn’t aware of the brand 
                                 and what it offers, we can’t expect them to make a purchase.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">3</h2>
                               <h3>Customer retention</h3>
                              <p>Being a new venture, we understood building customer loyalty would be a little hard at least in the initial phase..</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">4</h2>
                               <h3>Competitive niche</h3>
                              <p>A highly competitive niche that it has been, kitchen gardening as an industry saw its peak during COVID-19. Catching the customers’ eye, thus, became a challenge.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">5</h2>
                               <h3>IOS 14 Update</h3>
                              <p>After the June 2021 iOS 14 update, tracking users and how they interact with ads has become a struggle. As a result, 
                                 marketers don’t have the exact numbers regarding conversions, reach, impressions, and likewise. The update has made it difficult to target audiences, track conversions, 
                                 and optimise ads.</p>
                           </div>
                           <div className="inn-Challenges">
                                <img src={ATG_Challenge} alt="ATG Service" />
                           </div>
                          
                           </div>
                           <div className="whatwedid">
                              <h2 className="heading_main">What We Did</h2>
                              <div className="out-Challenges">
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">1</h2>
                                 <h3>Established the goal hierarchy</h3>
                                 <p>Setting a vision to have a million organic Facebook followers isn’t wrong, expecting to get them in a year with a limited budget is. 
                                    Because it’s a goal that is unlikely to be achieved, setting realistic goals becomes essential. We, therefore, established SMART (Specific, 
                                    Measurable, Attainable, Relevant, & Time-bound) short-term goals; keeping the bigger picture in mind.</p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">2</h2>
                                 <h3>Gave brand awareness a boost</h3>
                                 <p>Because AllThatGrows was a new brand in the market, we targeted the audience based on their interests which included vegetable farming, plant nursery, roof gardening, and more. We creamed off the interest to bring the audience from TOF (top of the funnel) to BOF (bottom of the funnel).
                                       </p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">3</h2>
                                 <h3>Pre-launched facebook ads</h3>
                                 <p>To drive maximum relevant traffic, we ran post engagement campaigns with discounts and offers flashing on them. A strategy of pre-launching Facebook ads 4-5 days before the weekly sale was executed. </p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">4</h2>
                                 <h3>Amplified post engagement</h3>
                                 <p>Engagement campaigns were run so the prospects could land on our Facebook and Instagram pages, thereby engaging with our posts. The whole idea behind this was to use this audience for retargeting purposes.</p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">5</h2>
                                 <h3>Maximised sales by spending less</h3>
                                 <p>We worked on retargeting campaigns based on “cart abandoned” and were successful in bringing more sales by spending less. We also created lookalike audiences by decoding the interests of the brand’s online visitors on the website, Facebook, & Instagram.</p>
                              </div>
                              <div className="inn-Challenges">
                                  <h2 className="heading_count">6</h2>
                                 <h3>Managed the budget to amplify sales</h3>
                                 <p>Once the campaigns started giving the desired results, we increased the budget by 60% to reach new audiences and remarketed to the old. This was done after careful consideration and proper analysis</p>
                              </div>
                           
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="cb_good_section call-action-component">
                        <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="diamond2"/>
                        </div>
                     </div>  

                      <div className="overall-roas ">
                        <div className="container">
                           <div className="out-roas">
                           <div className="inn-roas">
                              <h2>3.27x</h2>
                              <p>Overall ROAS</p>
                           </div>
                           <div className="inn-roas">
                              <h2>60,012</h2>
                              <p>Average Clicks</p>
                           </div>
                           <div className="inn-roas">
                              <h2>39,94,569</h2>
                              <p>Average Impressions</p>
                           </div>
                           </div>
                        </div>
                     </div>

                     <div className="footer-atg ">
                        <div className="container">
                        <p>Want to maximise your ROAS? Reach out to us at letstalk@cueforgood.com and we’ll get back to you with
                              how we can help your business grow. </p>
                               <img src={ATG_Foot} alt="ATG Microgreen Kit" />
                        </div>
                     </div>                  


                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top" className="focus-visible"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}